import React from "react";

const BlackFooter = () => {
  return (
    <footer className="bg-black text-white py-4">
      <div className="container mx-auto flex flex-col items-center justify-center space-y-3">

        <a
          href="https://esolutionglobal.com/exye-app-release.apk"
          download
          className="bg-red-500 hover:bg-red-700 text-white px-20 py-2 rounded text-sm sm:text-base font-bold transition"
        >
          Download Now
        </a>
      </div>
    </footer>
  );
};

export default BlackFooter;
