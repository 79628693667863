import React from "react";
import crousal1 from "../asset/crousal1.png"

const contests = [
  { name: "Pico", fee: "RS 20" },
  { name: "nano", fee: "RS 30" },
  { name: "mini", fee: "RS 40" },
  { name: "kilo", fee: "RS 50" },
  { name: "mega", fee: "RS 100" },
  { name: "giga", fee: "RS 250" },
  { name: "tera", fee: "RS 500" },
];

function FantasyCricket() {
  return (
    <div id="home" className="flex flex-col md:flex-row items-center justify-center bg-[#FEE79B] py-10 px-5 md:px-20 font-poppins">
      <div className="md:w-1/2 text-center md:text-left">
        <h1 className="lg:text-3xl text-2xl md:text-4xl font-bold text-[#F05A5B] mb-6">
          Exye: The Ultimate Online Quiz App
        </h1>
        <p className="text-gray-700 mb-4">
          Exye is an exciting online quiz platform where knowledge reigns
          supreme, and luck takes a backseat. Dive into engaging contests across
          a wide range of topics, including history, entertainment, geography,
          astronomy, and science. <br></br> 
          <br />Simply pick a contest that interests you, pay
          the entry fee, and compete with other participants to be the quickest
          to answer correctly and claim the prize money. With fresh questions
          added every hour, Exye ensures a dynamic and ever-evolving Quiz
          experience.<br></br> <br /> Whether you're a trivia enthusiast or a casual player,
          there's always a new challenge waiting for you. And the best part? You
          can withdraw your winnings anytime, giving you complete control over
          your rewards.<br></br> Ready to showcase your wisdom and earn exciting prizes?
          Join Exye now and let the Quiz begin!
        </p>

        {/* table content start */}
    <div className="max-w-[700px] mx-auto pb-10 ">
      <table className="w-full  border border-gray-300 ">
        <thead>
          <tr className="bg-gray-200 ">
            <th className="border border-gray-300 px-4 py-2 text-left text-[#F05A5B] text-xl ">Contest Name</th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#F05A5B] text-xl">Participation Fee</th>
          </tr>
        </thead>
        <tbody>
          {contests.map((contest, index) => (
            <tr key={index} className="hover:bg-gray-100 ">
              <td className="border border-gray-300 px-4 py-2  text-xl">{contest.name}</td>
              <td className="border border-gray-300 px-4 py-2 text-xl ">{contest.fee}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    {/* table content end */}

      <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
        <img
          src={crousal1}
          alt="EXYE App"
          className="w-full max-w-xs md:max-w-md"
        />
      </div>
    </div>
  );
}

export default FantasyCricket;
