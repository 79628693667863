import React, { useState } from "react";

const topicsData = [
  {
    title: "Getting Started",
    description: "What is EXYE, getting the app & more",
    icon: "🚀",
    faqs: [
      { question: "What is EXYE", answer: "EXYE is Gaming platform." },
      {
        question: "How to download the app?",
        answer: "You can download it from the Play Store or App Store.",
      },
      {
        question: "How to create an account?",
        answer: "Sign up with your email or phone number.",
      },
    ],
  },
  {
    title: "My Balance",
    description: "Winnings, Withdrawals, Deposits & more",
    icon: "💼",
    faqs: [
      {
        question: "How to check my balance?",
        answer: "Go to the My Balance section in your profile.",
      },
      {
        question: "How to withdraw money?",
        answer: "Use the Withdraw option in the My Balance section.",
      },
      {
        question: "What is the minimum withdrawal?",
        answer: "The minimum withdrawal amount is --.",
      },
    ],
  },
  {
    title: "How To Play",
    description: "Player details, Contest & more",
    icon: "🏆",
    faqs: [
      {
        question: "How to verify my account?",
        answer: "Upload your ID proof in the Verification section.",
      },
      {
        question: "How to change profile details?",
        answer: "Go to the Edit Profile section to update details.",
      },
      {
        question: "Why is verification important?",
        answer: "It helps secure your account and winnings.",
      },
    ],
  },
  {
    title: "Other",
    description: "Legality, Safety, Verification & more ",
    icon: "👥",
    faqs: [
      {
        question: "How to verify my account?",
        answer: "Upload your ID proof in the Verification section.",
      },
      {
        question: "How to change profile details?",
        answer: "Go to the Edit Profile section to update details.",
      },
      {
        question: "Why is verification important?",
        answer: "It helps secure your account and winnings.",
      },
    ],
  },
];

const Help = () => {
  const [openTopicIndex, setOpenTopicIndex] = useState(null);

  const toggleTopic = (index) => {
    setOpenTopicIndex(openTopicIndex === index ? null : index);
  };

  return (
    <div className="max-w-full mx-auto my-8 px-4 lg:px-40 md:px-24">
      <h2 className="text-2xl font-bold mb-4">Topics</h2>

      {topicsData.map((topic, index) => (
        <div key={index} className="mb-4">
          <button
            className="w-full flex  justify-between items-center p-4 bg-white shadow rounded-lg"
            onClick={() => toggleTopic(index)}
          >
           <div className="flex items-start">
  <div>
    <h3 className="font-bold text-lg flex items-center">
      <span className="text-2xl mr-2">{topic.icon}</span>
      {topic.title}
    </h3>
    <p className="text-sm text-gray-500">{topic.description}</p>
  </div>
</div>


            <span className="text-xl">
              {openTopicIndex === index ? "▲" : "▼"}
            </span>
          </button>

          {openTopicIndex === index && (
            <div className="ml-4 mt-2 p-4 bg-gray-50 rounded-lg">
              {topic.faqs.map((faq, i) => (
                <div key={i} className="mb-2">
                  <h4 className="font-semibold text-sm">{faq.question}</h4>
                  <p className="text-gray-600 text-sm">{faq.answer}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Help;
